import React from "react"
import styled from "styled-components"
import Div100vh from "react-div-100vh"

import petrovicky from "./petrovicky.png"

const App = () => {
  return (
    <Container>
      <Title>Radek Petrovicky</Title>
      <Image />
    </Container>
  )
}

export default App

const Container = styled(Div100vh)`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: 4em;

  @media (max-width: 600px) {
    font-size: 10.666666666vw;
  }
`

const Image = styled.img.attrs({ width: "480", height: "480", src: petrovicky })`
  width: auto;
  height: auto;
  max-width: 90vw;
  max-height: 50vh;
`
